import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router-dom"
import BasePage, {BasePageProps} from "../BasePage"
import {ErrorType, SignupAnswer, SignupDto} from "../../api/dtos";
import {getSignupLink} from "../../api/api";
import FullPageMessage from "../../components/FullPageMessage";

interface SignupPageProps {
}

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    signup?: SignupDto
    fullPageErrorMessage?: string
    loading: boolean
}

type Props = BasePageProps & SignupPageProps & RouteComponentProps<{ linkId: string, groupplayId: string, answer: string }>

export default class SignupLinkPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false
        };
    }

    async componentDidMount() {
        const {linkId, groupplayId, answer} = this.props.match.params;
        const session = this.props.session;
        try {
            if(answer !== SignupAnswer.YES && answer !== SignupAnswer.NO && answer !== SignupAnswer.PAUSE) {
                this.setState({fullPageErrorMessage: "Felaktigt svar..", loading: false});
                return;
            }
            let signupDto: SignupDto = await getSignupLink(groupplayId, linkId, session ? session.sessionId : undefined);
            this.setState({signup: signupDto});

            setTimeout(() => {
                if (!session && signupDto.session) {
                    console.log('no session before, new session with signup!');
                    this.props.onSessionChange(false, signupDto.session);
                }
                if (session && signupDto.session && session.sessionId !== signupDto.session.sessionId) {
                    console.log('session before, but not same as invite.. using invite session!');
                    this.props.onSessionChange(true, undefined);
                    this.props.onSessionChange(false, signupDto.session);
                }
                this.setState({redirectTo: "/gruppspel/" + groupplayId + "/signupanswer/" + answer})
            }, 100);
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.INVALID_LINK_ID:
                    this.setState({fullPageErrorMessage: "Länken du klickade på är för gammal och inte giltid längre!", loading: false});
                    break;
                default:
                    this.setState({fullPageErrorMessage: error.type, loading: false});
                    break;
            }
        }
    }

    render() {
        if (this.state.fullPageErrorMessage) {
            return (<FullPageMessage text={this.state.fullPageErrorMessage}/>);
        }
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        return (<BasePage {...this.props} fatalError={this.state.fatalError} loading={this.state.loading}>
            </BasePage>
        );
    }
}
